import { ISelectOption } from '~/core/@types/global'

export const ACTIVITIES_TAB = 'activities'
export const JOBS_TAB = 'jobs'
export const NOTES_TAB = 'notes'
export const TASKS_TAB = 'tasks'
export const PLACEMENTS_TAB = 'placements'
export const FILES_TAB = 'files'

export const MAX_COMPANY_STATUS = 15

export const DOMAIN_EXISTED_TYPE = 'domain_existed'
export const FILTER_BY_DEFAULT = 'uncompleted'

export const OPTION_COMPANY_LISTING_SORT: ISelectOption[] = [
  {
    value: 'desc',
    label: 'desc',
    icon: 'ArrowDown'
  },
  {
    value: 'asc',
    label: 'asc',
    icon: 'ArrowUp'
  }
]
